.ant-modal{
  top: 20px;
  form{
    h3{
      border-bottom: 1px solid #e8e8e8;
      background: #fafafa;
      margin: -12px -24px 15px;
      padding: 5px 24px;
      &:first-child{
        margin: -24px -24px 15px;
      }
      &.title-categories{
        margin: -30px 0 -1px;
        padding: 5px 10px;
        background: transparent;
      }
    }
  }
}
.ant-modal-title{
  font-size: 18px;
}
