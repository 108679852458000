.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}
.ant-layout-sider .ant-menu {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .ant-layout-sider .ant-menu {
    margin-top: 0;
  }
}
@media (max-width: 896px) {
  .ant-layout-sider .ant-menu {
    padding-bottom: 48px;
  }
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical {
  border: 0;
}
.ant-menu-item a {
  font-weight: normal;
}
.ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
  margin: 0;
  padding: 16px 0;
}
.ant-table-thead > tr > th {
  padding: 8px 16px;
}
body .ant-tabs .ant-table-wrapper {
  border: 0;
}
body .ant-tabs .ant-row {
  border-radius: 0;
}
body .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
body .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}
body .ant-table-wrapper .ant-table-placeholder {
  border-bottom: 0;
}
body .ant-checkbox-inner:after {
  left: 3px;
}
body div.ant-typography,
body .ant-typography p {
  margin-bottom: 0;
}
.ant-spin-spinning {
  display: flex;
  align-items: center;
  height: calc(100vh - 168px);
  justify-content: center;
  flex-direction: column;
}
.ant-avatar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}
.right-column {
  text-align: right;
}
.right-column .ant-tag {
  margin-right: 0;
}
.right-column .date-box {
  display: inline-block;
  min-width: 105px;
  text-align: center;
}
.center-column {
  text-align: center;
}
.ant-tag {
  text-align: center;
  min-width: 105px;
}
.ant-badge-count {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.ant-list-bordered {
  border-color: #e8e8e8;
}
.tox-notifications-container,
.tox-statusbar__branding {
  display: none;
}
.tox-statusbar {
  display: none !important;
}
.tox.tox-tinymce {
  border-radius: 4px;
}
@media (max-width: 1024px) {
  .ant-table {
    font-size: 13px;
  }
  .ant-table .anticon-mail,
  .ant-table .anticon-phone {
    display: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
  .ant-tag,
  .right-column .date-box {
    min-width: 90px;
    padding: 0 5px;
    font-size: 12px;
  }
}
@media (max-width: 896px) {
  #root {
    overflow-y: hidden;
    max-height: 100vh;
  }
  .ant-layout-sider-has-trigger {
    overflow-y: auto;
    max-height: 100vh;
  }
  .hide-mobile {
    display: none!important;
  }
  .ant-modal {
    max-width: 90%!important;
  }
  .ant-btn {
    padding: 0 8px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 15px 0 0;
  }
}
@media (max-width: 767px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px;
  }
  .ant-tag,
  .right-column .date-box {
    min-width: 80px;
    padding: 0 5px;
    font-size: 11px;
  }
  .ant-form label {
    font-size: 13px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 10px 0 0;
  }
  .ant-list-item {
    padding: 8px 10px;
  }
  .ant-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  .ant-select,
  .search-input__value-container {
    font-size: 12px;
  }
  .ant-input {
    padding: 4px 6px;
    font-size: 12px;
  }
  .ant-calendar-range-picker-input {
    width: 41%  ;
  }
  .ant-table-pagination.ant-pagination {
    padding: 10px 0;
  }
  h3 {
    font-size: 1.1em;
  }
}
.ant-tabs-nav-container:not(.ant-tabs-nav-container-scrolling) {
  padding-left: 15px;
  padding-right: 15px;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
a {
  color: #2B3D4F;
  font-weight: bold;
}
.ant-btn-link {
  border: 0;
  color: #1890ff;
  text-decoration: underline;
  box-shadow: none;
}
.ant-btn-link.ant-btn-sm {
  font-size: 0.8rem;
}
.ant-btn-link.checkboxes {
  margin: 0;
  padding: 0;
}
.ant-btn-circle.ant-btn-icon-only {
  line-height: 28px;
}
.ant-modal-content .btn-margin {
  margin-bottom: 28px;
}
.ant-btn-danger {
  border-color: #d9d9d9;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #ff7859;
  background-color: #fff;
  border-color: #ff7859;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40PX;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  box-shadow: none;
  border-color: #a4b0bb;
}
body .ant-input,
body .ant-input-affix-wrapper,
body .search-input .search-input__control,
.ant-input-search,
.ant-input-number {
  background: #fff;
  outline: none;
  box-shadow: none;
  border-color: #d9d9d9;
}
body .ant-input:focus,
body .ant-input-affix-wrapper:focus,
body .search-input .search-input__control:focus,
.ant-input-search:focus,
.ant-input-number:focus,
body .ant-input:hover,
body .ant-input-affix-wrapper:hover,
body .search-input .search-input__control:hover,
.ant-input-search:hover,
.ant-input-number:hover {
  box-shadow: none;
  border-color: #a4b0bb;
}
.ant-form-item-control .search-input .search-input__control:hover,
.ant-form-item-control .search-input .search-input__control.search-input__control--is-focused {
  border-color: #a4b0bb;
}
.ant-form-item-control .search-input__option:active,
.ant-form-item-control .search-input__option:hover {
  background-color: rgba(243, 78, 45, 0.05);
}
.ant-form-item-control .search-input__option.search-input__option--is-focused {
  background-color: rgba(243, 78, 45, 0.05);
}
.ant-form-item-control .search-input__option.search-input__option--is-selected {
  background-color: rgba(243, 78, 45, 0.12);
  color: rgba(0, 0, 0, 0.65);
}
.ant-form-item-control .search-input__option.search-input__option--is-selected:active,
.ant-form-item-control .search-input__option.search-input__option--is-selected:hover {
  background-color: rgba(243, 78, 45, 0.12);
}
.search-input__control .search-input__placeholder {
  color: #bfbfbf;
}
.rdw-dropdown-selectedtext {
  color: #333;
}
.rdw-dropdown-selectedtext:hover {
  color: #333;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: middle;
  order: 2;
  flex: 0 1 auto;
}
.ant-form-inline .ant-form-item > .ant-form-item-control {
  flex: 0 0 auto;
  align-self: flex-start;
  order: 1;
}
.ant-form-inline .ant-form-item .ant-form-item-control {
  line-height: unset;
  margin-right: 10px;
}
.ant-layout-header .ant-input-search {
  border: 0;
  color: #fff;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.2);
}
.ant-layout-header .ant-input-search .ant-input {
  background: none;
  border-radius: 2px;
  height: 32px;
  padding: 4px 30px 4px 11px;
  color: #fff;
}
.ant-layout-header .ant-input-search .ant-input::placeholder {
  color: #fff;
}
.ant-layout-header .ant-input-search .ant-input:focus {
  color: #333;
  background: #fff;
}
.ant-layout-header .ant-input-search .ant-input:focus::placeholder {
  color: #ccc;
}
.ant-layout-header .ant-input-search .ant-input:focus + .ant-input-suffix {
  color: #333;
}
.ant-layout-header .ant-input-search .ant-input-suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.ant-layout-header .ant-input-search .ant-input-search-icon:before {
  display: none;
}
.ant-layout-header .ant-input-suffix {
  color: #fff;
}
.ant-layout-header .ant-input-suffix .ant-input-search-icon {
  color: inherit;
}
body .ant-select-selection:hover,
body .ant-select-selection:focus {
  border-color: #a4b0bb;
  box-shadow: none;
}
body .ant-select-dropdown-menu-item:hover,
body .ant-select-dropdown-menu-item-active {
  background-color: rgba(243, 78, 45, 0.05) !important;
  color: #333 !important;
}
body .ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  font-weight: 400;
  color: #333 !important;
  background-color: rgba(243, 78, 45, 0.12) !important;
}
body .ant-form-item-label > label::after {
  content: ' ';
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(243, 78, 45, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  color: inherit;
}
.ant-input-affix-wrapper {
  padding: 0;
  position: relative;
}
.ant-input-affix-wrapper .ant-input-prefix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  transform: translateY(-50%);
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-search.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.ant-input-search.ant-input-affix-wrapper .ant-input-search-icon:before {
  display: none;
}
.ant-input-search.ant-input-affix-wrapper input {
  padding: 4px 30px 4px 11px;
  border-radius: 4px;
}
.ant-picker {
  min-width: 150px;
  width: 100%;
}
.ant-picker:hover,
.ant-picker .ant-picker-focused {
  border-color: #40a9ff;
}
.ant-form-item-has-error .editor-wrap,
.ant-form-item-has-error .tox-tinymce {
  border-color: #f5222d;
}
.tox-notifications-container,
.tox-statusbar__branding {
  display: none;
}
.tox-statusbar {
  display: none !important;
}
.tox-tinymce-inline {
  z-index: 2000;
}
.tox.tox-silver-sink {
  z-index: 2100;
}
.ant-tree {
  position: relative;
}
.ant-tree .ant-tree-treenode {
  width: 100%;
  justify-content: space-between;
}
.ant-tree > .ant-tree-node-content-wrapper:hover .category-controls .anticon {
  display: inline;
}
.ant-tree .add-category-btn {
  margin: -5px 0 0 20px;
}
.ant-tree .add-category-btn .ant-tree-node-content-wrapper {
  background: transparent;
  border: 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  padding: 6px 40px 6px 5px;
  white-space: normal;
  height: auto;
  width: calc(100% - 30px);
  z-index: 9;
  display: inline-block;
}
.ant-tree .ant-tree-node-content-wrapper .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  position: absolute;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  padding: 6px 55px 6px 5px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .category-label {
  opacity: 1;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .category-label svg path {
  fill: #ff7859;
}
.ant-tree .category-controls > div {
  left: -20px;
}
.ant-tree .category-controls > div {
  left: -35px;
}
.ant-tree .category-controls > div {
  left: -55px;
}
.ant-tree span.ant-tree-switcher,
.ant-tree .ant-tree span.ant-tree-iconEle {
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  margin-top: 3px;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: rgba(243, 78, 45, 0.05);
}
.ant-tree .ant-tree-node-content-wrapper:hover .category-label {
  opacity: 1;
}
.ant-tree .ant-tree-node-content-wrapper:hover .category-label svg path {
  fill: #ff7859;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(243, 78, 45, 0.12);
}
.ant-tree .anticon-minus-square,
.ant-tree .anticon-plus-square {
  background-color: #fff;
  z-index: 99;
  position: relative;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 14px;
}
.ant-tree li span.ant-tree-checkbox {
  margin: 5px 4px 0 2px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: transparent;
  height: 40px;
  line-height: 40px;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  height: calc(100% - 21px);
  top: 16px;
  content: '';
}
.ant-tree span.ant-tree-switcher.ant-tree-switcher-noop i {
  background: #fff;
}
.ant-tree-switcher-line-icon.anticon-file:before {
  content: '';
  display: inline-block;
  position: relative;
  top: -1px;
  width: 15px;
  height: 15px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjE1cHgiIHZpZXdCb3g9IjAgMCAxNSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbD0iI0I0QjRCNCIgeD0iMSIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iNiI+PC9yZWN0PiAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbD0iI0I0QjRCNCIgeD0iNiIgeT0iNCIgd2lkdGg9IjMiIGhlaWdodD0iMTEiPjwvcmVjdD4gICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIGZpbGw9IiNCNEI0QjQiIHg9IjExIiB5PSIwIiB3aWR0aD0iMyIgaGVpZ2h0PSIxNSI+PC9yZWN0PiAgICA8L2c+PC9zdmc+');
}
.ant-tree-switcher-line-icon.anticon-file svg {
  display: none;
}
.ant-modal {
  top: 20px;
}
.ant-modal form h3 {
  border-bottom: 1px solid #e8e8e8;
  background: #fafafa;
  margin: -12px -24px 15px;
  padding: 5px 24px;
}
.ant-modal form h3:first-child {
  margin: -24px -24px 15px;
}
.ant-modal form h3.title-categories {
  margin: -30px 0 -1px;
  padding: 5px 10px;
  background: transparent;
}
.ant-modal-title {
  font-size: 18px;
}
