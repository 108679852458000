.ant-input-affix-wrapper .ant-input:not(:first-child){
  padding-left: 40PX;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled){
  box-shadow: none;
  border-color: #a4b0bb;
}
body .ant-input,body .ant-input-affix-wrapper, body .search-input .search-input__control,.ant-input-search, .ant-input-number {
  background: #fff;
  outline: none;
  box-shadow: none;
  border-color: #d9d9d9;
  &:focus,&:hover{
    box-shadow: none;
    border-color: #a4b0bb;
  }
}
.ant-form-item-control{
  .search-input .search-input__control{
    &:hover,&.search-input__control--is-focused{
      border-color: #a4b0bb;
    }
  }
  .search-input__option{
    &:active,&:hover {
      background-color: rgba(243, 78, 45, 0.05);
    }
    &.search-input__option--is-focused{
      background-color: rgba(243, 78, 45, 0.05);
    }
    &.search-input__option--is-selected{
      background-color: rgba(243, 78, 45, 0.12);
      color: rgba(0, 0, 0, 0.65);
      &:active,&:hover{
        background-color: rgba(243, 78, 45, 0.12);
      }
    }
  }
}
.search-input__control .search-input__placeholder{
  color: #bfbfbf;
}
.rdw-dropdown-selectedtext{
  color: #333;
  &:hover{
    color: #333;
  }
}
.ant-form-inline .ant-form-item{
  & > .ant-form-item-control-wrapper,& > .ant-form-item-label{
    display: inline-block;
    vertical-align: middle;
    order: 2;
    flex: 0 1 auto;
  }
  & > .ant-form-item-control{
    flex: 0 0 auto;
    align-self: flex-start;
    order: 1;
  }
  .ant-form-item-control{
    line-height: unset;
    margin-right: 10px;
  }
}
.ant-layout-header{
  .ant-input-search {
    border: 0;
    color: #fff;
    border-radius: 2px;
    background: rgba(255,255,255,.2);
    .ant-input{
      background: none;
      border-radius: 2px;
      height: 32px;
      padding: 4px 30px 4px 11px;
      color: #fff;
      &::placeholder{
        color: #fff;
      }
      &:focus{
        color: #333;
        background: #fff;
        &::placeholder{
          color: #ccc;
        }
        & + .ant-input-suffix{
          color: #333;
        }
      }
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    .ant-input-search-icon:before {
      display: none;
    }
  }
  .ant-input-suffix{
    color: #fff;
    .ant-input-search-icon{
      color: inherit;
    }
  }
}
body{
  .ant-select-selection{
    &:hover,&:focus{
      border-color: #a4b0bb;
      box-shadow: none;
    }
  }
  .ant-select-dropdown-menu-item:hover,.ant-select-dropdown-menu-item-active{
    background-color: rgba(243, 78, 45, 0.05)!important;
    color: #333!important;
  }
  .ant-select-dropdown{
    .ant-select-dropdown-menu-item-selected {
      font-weight: 400;
      color: #333!important;
      background-color: rgba(243, 78, 45, 0.12)!important;
    }
  }
  .ant-form-item-label > label::after{
    content: ' ';
  }
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(243, 78, 45, 0.05);
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-item {
    &.ant-dropdown-menu-item-selected {
      color: inherit;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  position: relative;
  .ant-input-prefix {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,.65);
    line-height: 0;
    transform: translateY(-50%);
    left: 12px;
  }
  .ant-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
}
.ant-input-search.ant-input-affix-wrapper {
  .ant-input-suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  .ant-input-search-icon:before {
    display: none;
  }
  input {
    padding: 4px 30px 4px 11px;
    border-radius: 4px;
  }
}

.ant-picker {
  min-width: 150px;
  width: 100%;
  &:hover, .ant-picker-focused {
    border-color: #40a9ff;
  }
}

.ant-form-item-has-error {
  .editor-wrap,.tox-tinymce {
    border-color: #f5222d;
  }
}

.tox-notifications-container,
.tox-statusbar__branding {
    display: none;
}
.tox-statusbar {
    display: none !important;
}
.tox-tinymce-inline {
    z-index: 2000;
}
.tox.tox-silver-sink {
    z-index: 2100;
}
