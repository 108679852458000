.ant-tree {
  position: relative;
  .ant-tree-treenode {
    width: 100%;
    justify-content: space-between;
  }
  & > .ant-tree-node-content-wrapper:hover{
    .category-controls .anticon {
      display: inline;
    }
  }
  .add-category-btn{
    margin: -5px 0 0 20px;
    .ant-tree-node-content-wrapper {
      background: transparent;
      border: 0;
    }
  }
  .ant-tree-node-content-wrapper{
    position: relative;
    background: #fafafa;
    border: 1px solid #f1f1f1;
    padding: 6px 40px 6px 5px;
    white-space: normal;
    height: auto;
    width: calc(100% - 30px);
    z-index: 9;
    display: inline-block;
    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only){
      position: absolute;
    }
    &.ant-tree-node-selected {
      padding: 6px 55px 6px 5px;
      .category-label {
        opacity: 1;
        svg path {
          fill: #ff7859;
        }
      }
    }
  }
  .category-controls > div{
    left: -20px;
  }
  .category-controls > div{
    left: -35px;
  }
  .category-controls > div{
    left: -55px;
  }
  span.ant-tree-switcher, .ant-tree span.ant-tree-iconEle {
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    margin-top: 3px;
  }
  .ant-tree-node-content-wrapper:hover{
    background-color: rgba(243, 78, 45, 0.05);
    .category-label {
      opacity: 1;
      svg path {
        fill: #ff7859;
      }
    }
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background-color: rgba(243, 78, 45, 0.12);
  }
  .anticon-minus-square,.anticon-plus-square{
    background-color: #fff;
    z-index: 99;
    position: relative;
  }
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 14px;
}
.ant-tree li span.ant-tree-checkbox {
  margin: 5px 4px 0 2px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher{
  background: transparent;
  height: 40px;
  line-height: 40px;
}
.ant-tree-show-line .ant-tree-indent-unit::before{
  height: calc(100% - 21px);
  top: 16px;
  content: '';
}
.ant-tree span.ant-tree-switcher.ant-tree-switcher-noop i{
  background: #fff;
}
.ant-tree-switcher-line-icon.anticon-file{
  &:before{
    content: '';
    display: inline-block;
    position: relative;
    top: -1px;
    width: 15px;
    height: 15px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjE1cHgiIHZpZXdCb3g9IjAgMCAxNSAxNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbD0iI0I0QjRCNCIgeD0iMSIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iNiI+PC9yZWN0PiAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbD0iI0I0QjRCNCIgeD0iNiIgeT0iNCIgd2lkdGg9IjMiIGhlaWdodD0iMTEiPjwvcmVjdD4gICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIGZpbGw9IiNCNEI0QjQiIHg9IjExIiB5PSIwIiB3aWR0aD0iMyIgaGVpZ2h0PSIxNSI+PC9yZWN0PiAgICA8L2c+PC9zdmc+');
  }
  svg{
    display: none;
  }
}
