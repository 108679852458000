a {
  color: #2B3D4F;
  font-weight: bold;
}
.ant-btn-link {
  border: 0;
  color: #1890ff;
  text-decoration: underline;
  box-shadow: none;

  &.ant-btn-sm{
    font-size: 0.8rem;
  }

  &.checkboxes {
    margin: 0;
    padding: 0;
  }
}

.ant-btn-circle {
  &.ant-btn-icon-only {
    line-height: 28px;
  }
}

.ant-modal-content {
  .btn-margin {
    margin-bottom: 28px;
  }
}

.ant-btn-danger {
  border-color: #d9d9d9;
  &:hover, &:focus {
    color: #ff7859;
    background-color: #fff;
    border-color: #ff7859;
  }
}
