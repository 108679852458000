.ant-menu-inline .ant-menu-item{
  margin-top: 0;
}
.ant-layout-sider{
  .ant-menu{
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    @media (max-width: 896px) {
      padding-bottom: 48px;
    }
  }
  .ant-menu-inline,.ant-menu-vertical{
    border: 0;
  }
}
.ant-menu-item {
  a {
    font-weight: normal;
  }
}
.ant-table-pagination.ant-pagination{
  float: none;
  text-align: center;
  margin: 0;
  padding: 16px 0;
}
.ant-table-thead > tr > th{
  padding: 8px 16px;
}
body{
  .ant-tabs{
    .ant-table-wrapper{
      border: 0;
    }
    .ant-row{
      border-radius: 0;
    }
  }
  .ant-table-small > .ant-table-content > .ant-table-body{
    margin: 0;
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0;
  }
  .ant-table-wrapper{
    .ant-table-placeholder{
      border-bottom: 0;
    }
  }
  .ant-checkbox-inner:after{
    left: 3px;
  }
  div.ant-typography, .ant-typography p {
    margin-bottom: 0;
  }
}
.ant-spin-spinning {
  display: flex;
  align-items: center;
  height: calc(100vh - 168px);
  justify-content: center;
  flex-direction: column;
}
.ant-avatar{
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}
.right-column{
  text-align: right;
  .ant-tag{
    margin-right: 0;
  }
  .date-box{
    display: inline-block;
    min-width: 105px;
    text-align: center;
  }
}
.center-column{
  text-align: center;
}
.ant-tag{
  text-align: center;
  min-width: 105px;
}
.ant-badge-count{
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
.ant-list-bordered{
  border-color: #e8e8e8;
}

.tox-notifications-container,
.tox-statusbar__branding {
  display: none;
}

.tox-statusbar {
  display: none !important;
}

.tox.tox-tinymce {
  border-radius: 4px;
}

@media (max-width: 1024px) {
  .ant-table {
    font-size: 13px;
    .anticon-mail,.anticon-phone {
      display: none;
    }
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px;
  }
  .ant-tag, .right-column .date-box {
    min-width: 90px;
    padding: 0 5px;
    font-size: 12px;
  }
}
@media (max-width: 896px) {
  #root {
    overflow-y: hidden;
    max-height: 100vh;
  }
  .ant-layout-sider-has-trigger {
    overflow-y: auto;
    max-height: 100vh;
  }
  .hide-mobile {
    display: none!important;
  }
  .ant-modal {
    max-width: 90%!important;
  }
  .ant-btn {
    padding: 0 8px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 15px 0 0;
  }
}
@media (max-width: 767px) {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 5px;
  }
  .ant-tag,.right-column .date-box {
    min-width: 80px;
    padding: 0 5px;
    font-size: 11px;
  }
  .ant-form label {
    font-size: 13px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 10px 0 0;
  }
  .ant-list-item {
    padding: 8px 10px;
  }
  .ant-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  .ant-select,.search-input__value-container {
    font-size: 12px;
  }
  .ant-input {
    padding: 4px 6px;
    font-size: 12px;
  }
  .ant-calendar-range-picker-input {
    width: 41%  ;
  }
  .ant-table-pagination.ant-pagination {
    padding: 10px 0;
  }
  h3 {
    font-size: 1.1em;
  }
}
.ant-tabs-nav-container:not(.ant-tabs-nav-container-scrolling) {
  padding-left: 15px;
  padding-right: 15px;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
